const endpoints = {
  auth: {
    login: '/login',
    register: '/register',
    forgetPassword: '/user/reset-password-email',
    resetPassword: '/user/reset-password',
    inviteUser: 'invite-user',
    change_password:"change-password"
  },

  orders: {
    root: '/orders',
  },
  quotes: {
    root: '/quotes',
    detail: '/quotes/detail',
    approve: 'quote/approve'
  },
  scratchCard: {
    reward: '/api/v1/reward-used',
  },

  languagePair: '/top-language-pairs',
  orderDetail: '/orders/detail/',
  chart: '/report',
};

export default endpoints;
